import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/garment',
            component: { render: () => h(RouterView) },
            children: [
                {
                    path: '/garment',
                    name: 'garment',
                    component: () => import("./views/garment.vue")
                },
                {
                    path: '/garment/:identifier',
                    name: 'garmentdetail',
                    component: () => import("./views/garmentDetail.vue")
                },
                {
                    path: '/footwear/:identifier',
                    name: 'footweardetail',
                    component: () => import("./views/footwearDetail.vue")
                },
                {
                    path: '',
                    name: 'garmentUpperlayout',
                    meta: {
                        saveToGarmentConfiguration: true,
                        name: 'editbaseinformation'
                    },
                    component: () => import("@/views/calculator/views/estimation/layout.vue"),
                    children: [
                        {
                            path: '',
                            name: 'garmenteditEstimationLayout',
                            meta: {
                                saveToGarmentConfiguration: true,
                                name: 'editbaseinformation'
                            },
                            component: () => import("@/views/calculator/views/estimation/editEstimationLayout.vue"),
                            children: [

                                {
                                    path: '/garmentDefaultData/editbaseinformation/:identifier',
                                    name: 'garmenteditbaseinformation',
                                    meta: {
                                        saveToGarmentConfiguration: true,
                                        name: 'editbaseinformation'
                                    },
                                    component: () => import("@/views/calculator/views/estimation/editEstimation/editBaseInformation.vue"),
                                },
                                {
                                    path: '/garmentDefaultData/rawmaterials/:identifier',
                                    name: 'garmenteditrawmaterials',
                                    meta: {
                                        saveToGarmentConfiguration: true,
                                        name: 'editrawmaterials'
                                    },
                                    component: () => import("@/views/calculator/views/estimation/editEstimation/editRawMaterials.vue"),
                                },
                                {
                                    path: '/garmentDefaultData/productionprocess/:identifier/:process',
                                    name: 'garmenteditproductionprocess',
                                    meta: {
                                        saveToGarmentConfiguration: true,
                                        name: 'editproductionprocess'
                                    },
                                    component: () => import("@/views/calculator/views/estimation/editEstimation/editProcess.vue"),
                                },
                                {
                                    path: '/garmentDefaultData/:identifier/finaltransportation',
                                    name: 'garmentfinaltransportation',
                                    meta: {
                                        saveToGarmentConfiguration: true,
                                        name: 'finaltransportation'
                                    },
                                    component: () => import("@/views/calculator/views/estimation/editEstimation/editFinalTransportation.vue"),
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ];
};