import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/servicesSold',
            name: 'servicesSold',
            component: () => import("./views/servicesSold.vue")
        }
    ];
};