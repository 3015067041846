
import { mixins, Options } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import WithModel from '../mixins/withModel';
import store from '@/store';
import * as OM from "@/Model";
import * as VM from "@/viewModel";
import SelectModal from '../modal/selectModal.vue';

@Options({
    components: {
    }
})
export default class SelectFieldString extends mixins(WithModel) {

    @Prop() list: string[];
    @Prop() labelKey: string;
    @Prop() labelText: string;
    @Prop({ default: 0 }) tabIndex: number;
    @Prop({ default: "" }) placeholderKey: string;
    @Prop({ default: "" }) placeholderValue: string;
    @Prop({ default: false }) readonly: boolean;
    @Prop({ default: false}) showIdentifier: boolean;

    localList: string[] = null;
    preferredCulture: string = "";

    created() {
        this.preferredCulture = store.state.loggedUser.preferredCulture;
        if (!this.list)
            return;

        this.init();
    }

    get getDefaultValue(){
        if(typeof this.localValue == "object")
            return null;

        return "";
    }

    init() {
        this.localList = this.list.filter(x => x).sort((a, b) => {
            var compare1 = a.trim().toLowerCase();
            var compare2 = b.trim().toLowerCase();

            if (compare1 < compare2)
                return -1;
            else if (compare1 > compare2)
                return 1;
            else
                return 0;
        });

        var find = this.list.find(x => !x);

        if (find)
            this.localList.unshift(find);
    }

    @Watch("list.length")
    changedList() {
        this.init();
    }

    openModal(){
        this.$opModal.show(SelectModal, {
            list: this.localList,
            identifierSelected: this.modelValue,
            confirm: (identifier: string) => {
                this.localValue = identifier;
                this.$opModal.closeLast();
            }
        })
    }
    
}
