import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "edmondClimateContainer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner_container = _resolveComponent("spinner-container")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_modals_container = _resolveComponent("modals-container")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_spinner_container),
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component, route }) => [
            _createVNode(_Transition, {
              name: "opacity",
              class: "duration04 no_leave_animation",
              appear: ""
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }),
        _createVNode(_component_modals_container)
      ]))
    : _createCommentVNode("", true)
}