import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/logo.png'


const _hoisted_1 = { class: "text-center py-4" }
const _hoisted_2 = { class: "sideBarContent" }
const _hoisted_3 = { class: "upper" }
const _hoisted_4 = { class: "logout_div" }
const _hoisted_5 = { class: "fw_600 d-block mb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sideBar", { menuOpened: _ctx.isMenuOpen }])
  }, [
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("img", {
        class: "logo_image pointer",
        src: _imports_0,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/garment')))
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          class: "navItem",
          to: "/garment",
          alt: "Garment"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_localized_text, {
              class: "fw_600",
              localizedKey: "Garment",
              text: "Garment"
            })
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "navItem",
          to: "/transport",
          alt: "Transport"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_localized_text, {
              class: "fw_600",
              localizedKey: "Transport",
              text: "Transport"
            })
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "navItem",
          to: "/electricity",
          alt: "Electricity Emission"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_localized_text, {
              class: "fw_600",
              localizedKey: "Electricity Emission",
              text: "Electricity Emission"
            })
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "navItem",
          to: "/raw-materials",
          alt: "Raw Materials"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_localized_text, {
              class: "fw_600",
              localizedKey: "Raw Materials",
              text: "Raw Materials"
            })
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "navItem",
          to: "/production-phase",
          alt: "Production Phase"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_localized_text, {
              class: "fw_600",
              localizedKey: "Production Phase",
              text: "Production Phase"
            })
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "navItem",
          to: "/production-process",
          alt: "Production Process"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_localized_text, {
              class: "fw_600",
              localizedKey: "Production Process",
              text: "Production Process"
            })
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "navItem",
          to: "/servicesSold",
          alt: "Services Sold"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_localized_text, {
              class: "fw_600",
              localizedKey: "Services Sold",
              text: "Services Sold"
            })
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "navItem",
          to: "/companies",
          alt: "Companies"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_localized_text, {
              class: "fw_600",
              localizedKey: "Companies",
              text: "Companies"
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$store.state.loggedUser.username), 1),
      _createElementVNode("a", {
        class: "logout_clickable",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.logout()))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Logout",
          text: "Logout"
        })
      ])
    ])
  ], 2))
}