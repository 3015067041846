import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/servicesSoldCatalog',
            name: 'servicesSoldCatalog',
            component: () => import("./views/servicesSoldCatalog.vue")
        }
    ];
};