import { RouteRecordRaw, RouterView } from 'vue-router';
import { h } from 'vue';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/providers',
            component: { render: () => h(RouterView) },
            children: [
                {
                    path: '/providers',
                    name: 'providers',
                    component: () => import("./views/providers.vue")
                },
                {
                    path: '/edit-provider/:providerIdentifier',
                    name: 'edit provider',
                    component: () => import("./views/editProvider.vue")
                },
            ]
        }
    ];
};