
import WithModel from '../mixins/withModel';
import { mixins, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class InputNumber extends mixins(WithModel) {
    
    @Prop({
        default: 0
    }) tabIndex;

    @Prop({
        default: ""
    }) placeholderKey;

    @Prop({
        default: ""
    }) placeholderValue;

    @Prop({
        default: ""
    }) placeholderPrevText: string;

    @Prop({
        default: ""
    }) placeholderAfterText: string;

    @Prop({
        default: false
    }) readonly: boolean;

    @Prop({
        default: false
    }) disabled: boolean;

    @Prop() step: number;
    @Prop() min: number;
    @Prop() max: number;

}
