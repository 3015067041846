
import { mixins, Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { Prop } from 'vue-property-decorator';
import WithModel from '@/mixins/withModel';

@Options({})
export default class optionsSelector extends mixins(WithModel) {

    declare localValue: any[];

    @Prop() options: any[];
    @Prop() keyProp: string;
    @Prop() labelProp: string;

    search: string = "";

    indexFunct(model: any, item: any){
        let modelKey = this.keyProp ? model[this.keyProp] : model;
        let itemKey = this.keyProp ? item[this.keyProp] : item;
        return modelKey == itemKey;
    }

    labelValue(item: any){
        return this.labelProp ? item[this.labelProp] : item;
    }
    
    toggleOption(item: any){
        let indexOf = this.localValue.findIndex((x) => this.indexFunct(x, item));
        console.log(indexOf);
        if(indexOf == -1){
            this.localValue.push(item);
        } else {
            this.localValue.splice(indexOf, 1);
        }
    }

    isSelected(item){
        return this.localValue.findIndex((x) => this.indexFunct(x, item)) > -1;
    }

    get filteredOptions(){
        if(!this.search){
            return this.options;
        }
        return this.options.filter(x => this.labelValue(x).toLowerCase().trim().indexOf(this.search.toLowerCase().trim()) > -1)
    }

    all(){
        this.localValue.splice(0);
        this.localValue.push(...this.filteredOptions);
    }

    none(){
        this.localValue.splice(0);
    }

}
