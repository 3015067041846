import { vModelText as _vModelText, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["tabindex", "readonly"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_localizedPlaceholder = _resolveDirective("localizedPlaceholder")!

  return _withDirectives((_openBlock(), _createElementBlock("input", {
    type: "date",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
    onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.isoAndEmit && _ctx.isoAndEmit(...args))),
    tabindex: _ctx.tabIndex,
    readonly: _ctx.readonly,
    class: _normalizeClass({ no_value : !_ctx.localValue})
  }, null, 42, _hoisted_1)), [
    [_vModelText, _ctx.localValue],
    [_directive_localizedPlaceholder, {key: _ctx.placeholderKey, value: _ctx.placeholderValue, prevText: _ctx.placeholderPrevText, afterText: _ctx.placeholderAfterText }]
  ])
}