
import router from '@/router';
import { LoginServices } from '@/services/LoginServices';
import { CompanyClient, EmployeeClient, EstimationClient, GarmentClient } from '@/services/Services';
import { StorageServices } from '@/services/StorageServices';
import store from '@/store';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import UpdatePasswordModal from './modals/updatePasswordModal.vue';
import { Watch } from 'vue-property-decorator';

@Options({
  components: { },
})
export default class Navbar extends Vue {

	isMenuOpen: boolean = true;
    apikey: string = "";
    licenses: OM.AvailableLicensesVM[] = [];
    roles = VM.Roles;

    created() {
        if(this.$store.state.loggedUser.role == this.roles.CompanyManager) {
            CompanyClient.getAPIKEY()
            .then(x => {
                this.apikey = x;
                this.init();
            })
        }
        else
            this.init();


        //ogni 15minuti controllo le licenze attive
        setTimeout(() => {
            this.init();
        }, 900000);
    }

    init(){
        Promise.all([
            store.state.baseEmployeeClient.getAvailableLicenses(),
        ])
        .then(xs => {
            this.licenses = xs[0];
        })
    }

    @Watch('$store.state.updateLicenses', { deep: true })
    updateLicenses() {
        this.init();
    }

    goToCalculation(item: OM.AvailableLicensesVM) {
        store.state.estimationIdentifier = "";

        GarmentClient.checkIsFootwear(item.garment.identifier)
        .then(x => {
            if(x)
                this.$router.push('/footwear-calculator/' + item.garment.identifier);
            else {
                GarmentClient.checkIsBag(item.garment.identifier)
                .then(x => {
                    if(x)
                        this.$router.push('/bag-calculator/' + item.garment.identifier);
                    else {
                        GarmentClient.checkIsFabric(item.garment.identifier)
                        .then(x => {
                            if(x)
                                this.$router.push('/fabric-calculator/' + item.garment.identifier);
                            else
                                this.$router.push('/calculator/' + item.garment.identifier);
                        })
                    }
                })

            }
        })
    }

    freeUpLicense(item: OM.AvailableLicensesVM){
        EstimationClient.exitService(item.garment.identifier)
        .then(x => {
            if(this.$route.name != 'dashboard'){
                this.$router.push('/dashboard');
            }
            this.init();
        })
    }

    get groupedLicenses() {
        if(this.licenses.length == 0)
            return [];

        let groupedByGarment = this.licenses
        .reduce((acc, item) => {
            if (!acc[item.garment.identifier]) {
                acc[item.garment.identifier] = [];
            }
            acc[item.garment.identifier].push(item);
            return acc;
        }, {});

        return groupedByGarment;
    }

    updatePwd() {
        this.$opModal.show(UpdatePasswordModal, {});
    }
    
    logout() {
        EstimationClient.exitFromAllServices()
        .then(x => {
            LoginServices.logout();
        })
    }

    get testUser() {
        var loggedUser = store.state.loggedUser;

        var show = false;
        if(loggedUser.username.indexOf("edmond") > -1 || loggedUser.username.indexOf("nike") > -1)
            show = true;

        return show;
    }

}
