import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { class: "max-height-400" }
const _hoisted_3 = { class: "list-group" }
const _hoisted_4 = ["selected", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
        placeholder: "Type to search"
      }, null, 512), [
        [_vModelText, _ctx.search]
      ]),
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-primary",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.all && _ctx.all(...args)))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "All",
          text: "All"
        })
      ]),
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-primary",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.none && _ctx.none(...args)))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "None",
          text: "None"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredOptions, (item, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "list-group-item d-flex align-items-center pointer",
            key: i,
            selected: _ctx.isSelected(item),
            onClick: ($event: any) => (_ctx.toggleOption(item))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["sameAsCheckbox me-2", {'active': _ctx.isSelected(item)}])
            }, null, 2),
            (_ctx.labelProp)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(item[_ctx.labelProp]), 1)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(item), 1)
                ], 64))
          ], 8, _hoisted_4))
        }), 128))
      ])
    ])
  ]))
}