
import { mixins, Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import WithModel from '../mixins/withModel'

@Options({
    components: {
    }
})
export default class inputDate extends mixins(WithModel) {

    @Prop({
        default: 0
    }) tabIndex;

    @Prop({
        default: ""
    }) placeholderKey;

    @Prop({
        default: ""
    }) placeholderValue;

  @Prop({
        default: ""
    }) placeholderPrevText: string;

    @Prop({
        default: ""
    }) placeholderAfterText: string;

    @Prop({
        default: false
    }) readonly: boolean;

    
    @Watch('modelValue')
    onModelValueChange(next, prev){
        this.localValue = this.validateDate(next);
    }

    isoAndEmit(){
        let toEmit = new Date(this.localValue);
        this.emitModel(toEmit.toISOString());
    }

    validateDate(inputDate: string){
        var stringDate = inputDate.toString();
        let date = new Date(stringDate);
        if(this.isValidDate(date)){
            let years = date.getFullYear().toString();
            while(years.length < 4){
                years = "0" + years;
            } 
            let months = (date.getMonth() + 1).toString();
            if(months.length < 2) months = "0" + months;
            let days = date.getDate().toString();
            if(days.length < 2) days = "0" + days;

            return years +  "-" + months + "-" + days;
        }
    }
    
    isValidDate(d: any) {
        return d instanceof Date && !isNaN(<any>d);
    }

}
