
import { Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from './services/StorageServices';
import SpinnerContainer from '@/components/standard/spinnerContainer.vue';
import store from './store';
import { LoginServices } from './services/LoginServices';
import { BaseAuthClient, LocalizationClient } from './services/Services';
import utils from './utils';

@Options({
    components: {
        SpinnerContainer
    }
})
export default class App extends Vue {

    loaded: boolean = false;

    created() {
        utils.assignViewWithServices(this);
        store.state.loggedUser = StorageServices.getLoggedUser();
        store.state.selectedLanguage = store.state.loggedUser.preferredCulture;

        this.init();
    }

    init(){
        Promise.all([
            LocalizationClient.getAppDictionary(),
            LocalizationClient.getEnabledLanguages(),
        ])
        .then( x => {
            store.state.consts.localizedValues = x[0];
            store.state.consts.enabledLanguages = x[1];
            this.loaded = true;

            this.checkLogin();
        })
    }

    checkLogin(){
        var token = StorageServices.getLoginToken();
        var refreshToken = StorageServices.getLoginRefreshToken();
        var otp = StorageServices.getLoginOtp();

        if(otp) {
            var username = StorageServices.getLoginUsername();
            BaseAuthClient.completeLoginByDomain({
                otp: otp,
                username: username
            })
            .then(x => {
                LoginServices.LoginCallback(x.token, x.refreshToken);
            })
            .catch(err => {
                console.log("err", err);
            }).finally( () => {
                StorageServices.resetOtpCredentials();
            })
        }
        else
            LoginServices.LoginCallback(token, refreshToken);
    }

    @Watch("$store.state.showSpinner")
    disableScroll(){
        var el = document.getElementById("body");
        
        if(el == null)
          return;
        
        if(store.state.showSpinner > 0)
            el.classList.add("overflow-hidden");
        else
            el.classList.remove("overflow-hidden");
    }
}
