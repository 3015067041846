import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e076484"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal formContainer" }
const _hoisted_2 = { class: "modal_header" }
const _hoisted_3 = { class: "modal_body mt-5" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "modal_footer mt-5" }
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_field = _resolveComponent("form-field")!
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_form_field, {
        class: "little",
        type: "text",
        modelValue: _ctx.search,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
        placeholderKey: "Cerca",
        placeholderValue: "Cerca"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredList, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["selection_div", {selected : item.identifier == _ctx.identifierSelected}]),
          key: item.identifier + item.text + index,
          onClick: ($event: any) => (_ctx.select(item.identifier))
        }, [
          _createElementVNode("span", {
            class: "fev_text5 fw_600",
            innerHTML: item.text
          }, null, 8, _hoisted_5)
        ], 10, _hoisted_4))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("button", {
        class: "fev_button bg_brand",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.select(_ctx.identifierSelected))),
        disabled: _ctx.isDisabled
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Conferma",
          text: "Conferma"
        })
      ], 8, _hoisted_7)
    ])
  ]))
}