import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-855a549e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-items-center justify-content-around" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = {
  key: 0,
  class: "mb-5"
}
const _hoisted_4 = { class: "cardContainer" }
const _hoisted_5 = ["placeholder"]
const _hoisted_6 = { ref: "cardnumber" }
const _hoisted_7 = { ref: "cardexpiry" }
const _hoisted_8 = { ref: "cardcvc" }
const _hoisted_9 = {
  ref: "carderrors",
  class: "mt-4 text-center text-danger",
  role: "alert"
}
const _hoisted_10 = {
  class: "d-flex align-items-center mt-3 pointer",
  for: "saveCardCheck"
}
const _hoisted_11 = { class: "fs_12 fw_600 greenWater" }
const _hoisted_12 = {
  class: "d-flex align-items-center mt-3 pointer",
  for: "termsAndConditionCheck"
}
const _hoisted_13 = { class: "fs_12 fw_600 greenWater" }
const _hoisted_14 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mini_spinner = _resolveComponent("mini-spinner")!
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_stripe_saved_cards = _resolveComponent("stripe-saved-cards")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("template", null, [
      (!_ctx.cards)
        ? (_openBlock(), _createBlock(_component_mini_spinner, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.cards && _ctx.cards.length > 0)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", {
                class: _normalizeClass(["cardBlock", { selected: !_ctx.showNewCard }]),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showNewCard = false))
              }, [
                _createVNode(_component_localized_text, {
                  localizedKey: "Saved credit cards",
                  text: "Saved credit cards"
                })
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(["cardBlock", { selected: _ctx.showNewCard }]),
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setShowNewCard && _ctx.setShowNewCard(...args)))
              }, [
                _createVNode(_component_localized_text, {
                  localizedKey: "New credit card",
                  text: "New credit card"
                })
              ], 2)
            ]),
            (_ctx.cards.length > 0 && !_ctx.showNewCard)
              ? (_openBlock(), _createBlock(_component_stripe_saved_cards, {
                  key: 0,
                  cards: _ctx.cards,
                  modelValue: _ctx.selectedCard,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedCard) = $event))
                }, null, 8, ["cards", "modelValue"]))
              : _createCommentVNode("", true),
            (_ctx.paying)
              ? (_openBlock(), _createBlock(_component_mini_spinner, { key: 1 }))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  (_ctx.selectedCard)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: _normalizeClass(["genericButton gradientBlueBg w-75 mx-auto mt-5 hideOnRds", !!!_ctx.selectedCard ? 'disabled' : '']),
                        disabled: !!!_ctx.selectedCard,
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.pay()))
                      }, [
                        _createVNode(_component_localized_text, {
                          localizedKey: "Proceed to payment",
                          text: "Proceed to payment"
                        })
                      ], 10, _hoisted_2))
                    : _createCommentVNode("", true)
                ], 64))
          ], 64))
        : _createCommentVNode("", true)
    ]),
    (_ctx.cards && _ctx.cards.length == 0 || _ctx.showNewCard && !!!_ctx.selectedCard)
      ? (_openBlock(), _createElementBlock("form", {
          key: 0,
          class: "paymentForm",
          ref: "paymentform",
          onSubmit: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.pay && _ctx.pay(...args)), ["prevent"]))
        }, [
          (_ctx.stripeKey.indexOf('pk_test') > -1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_localized_text, {
                  localizedKey: "Payment in test mode, enter 4242 4242 4242 4242 as the card number.<br /> For the other fields, any data will do.",
                  text: "Payment in test mode, enter 4242 4242 4242 4242 as the card number.<br /> For the other fields, any data will do."
                })
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_localized_text, {
              type: "label",
              class: "mb-1 d-block",
              localizedKey: "Card holder",
              text: "Card holder"
            }),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              placeholder: _ctx.$localizationService.getLocalizedValue('Card holder', 'Card holder'),
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.cardholderName) = $event))
            }, null, 8, _hoisted_5), [
              [_vModelText, _ctx.cardholderName]
            ]),
            _createVNode(_component_localized_text, {
              type: "label",
              class: "mt-3 mb-1 d-block",
              localizedKey: "Card number",
              text: "Card number"
            }),
            _createElementVNode("div", _hoisted_6, null, 512),
            _createVNode(_component_localized_text, {
              type: "label",
              class: "mt-3 mb-1 d-block",
              localizedKey: "Card expiration",
              text: "Card expiration"
            }),
            _createElementVNode("div", _hoisted_7, null, 512),
            _createVNode(_component_localized_text, {
              type: "label",
              class: "mt-3 mb-1 d-block",
              localizedKey: "Security code (cvc)",
              text: "Security code (cvc)"
            }),
            _createElementVNode("div", _hoisted_8, null, 512),
            _createElementVNode("div", _hoisted_9, null, 512)
          ]),
          (_ctx.paying)
            ? (_openBlock(), _createBlock(_component_mini_spinner, { key: 1 }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createElementVNode("label", _hoisted_10, [
                  _withDirectives(_createElementVNode("input", {
                    type: "checkbox",
                    class: "me-2",
                    id: "saveCardCheck",
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.saveCard) = $event))
                  }, null, 512), [
                    [_vModelCheckbox, _ctx.saveCard]
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_localized_text, {
                      localizedKey: "Save card for future payments",
                      text: "Save card for future payments"
                    })
                  ])
                ]),
                _createElementVNode("label", _hoisted_12, [
                  _withDirectives(_createElementVNode("input", {
                    type: "checkbox",
                    class: "me-2",
                    id: "termsAndConditionCheck",
                    required: "",
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.tempAcceptedTermsAndConditions) = $event))
                  }, null, 512), [
                    [_vModelCheckbox, _ctx.tempAcceptedTermsAndConditions]
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_localized_text, {
                      localizedKey: _ctx.textTermsAndCondition,
                      text: _ctx.textTermsAndCondition,
                      object: { termsAndCondition: _ctx.termsAndCondition}
                    }, null, 8, ["localizedKey", "text", "object"])
                  ])
                ]),
                _createElementVNode("button", {
                  class: _normalizeClass(["btn btn-primary d-block w-75 mx-auto mt-5 text-center", !_ctx.canPay ? 'disabled' : '']),
                  disabled: !_ctx.canPay,
                  type: "submit",
                  ref: "submit"
                }, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Proceed to payment",
                    text: "Proceed to payment"
                  })
                ], 10, _hoisted_14)
              ], 64))
        ], 544))
      : _createCommentVNode("", true)
  ]))
}