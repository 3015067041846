import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value", "tabindex", "readonly", "min", "max", "step", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_localizedPlaceholder = _resolveDirective("localizedPlaceholder")!

  return _withDirectives((_openBlock(), _createElementBlock("input", {
    type: "number",
    value: _ctx.localValue,
    onInput: [
      _cache[0] || (_cache[0] = ($event: any) => (_ctx.localValue = $event.target.value)),
      _cache[1] || (_cache[1] = ($event: any) => (_ctx.emitModel(_ctx.localValue)))
    ],
    tabindex: _ctx.tabIndex,
    readonly: _ctx.readonly,
    min: _ctx.min,
    max: _ctx.max,
    step: _ctx.step,
    disabled: _ctx.disabled
  }, null, 40, _hoisted_1)), [
    [_directive_localizedPlaceholder, { key: _ctx.placeholderKey, value: _ctx.placeholderValue,  prevText: _ctx.placeholderPrevText, afterText: _ctx.placeholderAfterText }]
  ])
}