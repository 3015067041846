import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/production-process',
            name: 'production process',
            component: () => import("./views/productionProcess.vue")
        }
    ];
};