import { RouteRecordRaw, RouterView, useRoute } from 'vue-router';
import { h } from 'vue';
import store from '@/store';
import router from '@/router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/calculator/:garmentIdentifier',
            name: 'layout',
            component: () => import("./views/estimation/layout.vue"),
            children: [
                {
                    path: '/calculator/:garmentIdentifier',
                    name: 'start',
                    component: () => import("./views/estimation/start.vue"),
                },
                {
                    path: '',
                    name: 'editestimationlayout',
                    component: () => import("./views/estimation/editEstimationLayout.vue"),
                    children: [
                        {
                            path: '/calculator/editbaseinformation/:identifier',
                            name: 'editbaseinformation',
                            component: () => import("./views/estimation/editEstimation/editBaseInformation.vue"),
                        },
                        {
                            path: '/calculator/rawmaterials/:identifier',
                            name: 'editrawmaterials',
                            component: () => import("./views/estimation/editEstimation/editRawMaterials.vue"),
                        },
                        {
                            path: '/calculator/productionprocess/:identifier/:process',
                            name: 'editproductionprocess',
                            component: () => import("./views/estimation/editEstimation/editProcess.vue"),
                        },
                        {
                            path: '/calculator/:identifier/finaltransportation',
                            name: 'finaltransportation',
                            component: () => import("./views/estimation/editEstimation/editFinalTransportation.vue"),
                        },
                        {
                            path: '/calculator/:identifier/summary',
                            name: 'summary',
                            component: () => import("./views/estimation/editEstimation/summary.vue"),
                        },
                    ]
                }
            ]
        },
        {
            path: '/footwear-calculator/:garmentIdentifier',
            name: 'footwear layout',
            component: () => import("./views/footwear/footwearLayout.vue"),
            children: [
                {
                    path: '/footwear-calculator/:garmentIdentifier',
                    name: 'footwear_start',
                    component: () => import("./views/footwear/start.vue"),
                },
                {
                    path: '/footwear-calculator/components-weights/:estimationIdentifier',
                    name: 'footwear_componentsWeights',
                    component: () => import("./views/footwear/componentsWeights.vue"),
                },
                {
                    path: '/footwear-calculator/components-materials/:estimationIdentifier',
                    name: 'footwear_componentsMaterial',
                    component: () => import("./views/footwear/componentsMaterial.vue"),
                },
                {
                    path: '/footwear-calculator/chemicals/:estimationIdentifier',
                    name: 'footwear_chemicals',
                    component: () => import("./views/footwear/chemicals.vue"),
                },
                {
                    path: '/footwear-calculator/accessories/:estimationIdentifier',
                    name: 'footwear_accessories',
                    component: () => import("./views/footwear/accessories.vue"),
                },
                {
                    path: '/footwear-calculator/processes-locations-and-transports/:estimationIdentifier',
                    name: 'footwear_processes',
                    component: () => import("./views/footwear/processesLocationsAndTransports.vue"),
                },
                {
                    path: '/footwear-calculator/packaging/:estimationIdentifier',
                    name: 'footwear_packaging',
                    component: () => import("./views/footwear/packaging.vue"),
                },
                {
                    path: '/footwear-calculator/summary/:estimationIdentifier',
                    name: 'footwear_summary',
                    component: () => import("./views/footwear/summary.vue"),
                },
            ]
        },
        {
            path: '/fabric-calculator/:garmentIdentifier',
            name: 'fabric layout',
            component: () => import("./views/fabric/fabricLayout.vue"),
            children: [
                {
                    path: '/fabric-calculator/:garmentIdentifier',
                    name: 'fabric start',
                    component: () => import("./views/fabric/start.vue"),
                },
                {
                    path: '/fabric-calculator/farming/:estimationIdentifier',
                    name: 'fabric farming',
                    component: () => import("./views/fabric/farming.vue"),
                },
                {
                    path: '/fabric-calculator/wool/:estimationIdentifier',
                    name: 'fabric wool',
                    component: () => import("./views/fabric/wool.vue"),
                },
                {
                    path: '/fabric-calculator/rawmaterials/:estimationIdentifier',
                    name: 'fabric raw materials',
                    component: () => import("./views/fabric/rawMaterials.vue"),
                },
                {
                    path: '/fabric-calculator/productionprocess/:estimationIdentifier/:process',
                    name: 'fabric production process',
                    component: () => import("./views/fabric/processes.vue"),
                },
                {
                    path: '/fabric-calculator/:estimationIdentifier/finaltransportation',
                    name: 'fabric final transportation',
                    component: () => import("./views/fabric/finalTransportation.vue"),
                },
                {
                    path: '/fabric-calculator/:estimationIdentifier/summary',
                    name: 'fabric summary',
                    component: () => import("./views/fabric/summary.vue"),
                },
            ]
        },
        {
            path: '/bag-calculator/:garmentIdentifier',
            name: 'bag layout',
            component: () => import("./views/bag/bagLayout.vue"),
            children: [
                {
                    path: '/bag-calculator/:garmentIdentifier',
                    name: 'bag_start',
                    component: () => import("./views/bag/start.vue"),
                },
                {
                    path: '/bag-calculator/components-weights/:estimationIdentifier',
                    name: 'bag_componentsWeights',
                    component: () => import("./views/bag/componentsWeights.vue"),
                },
                {
                    path: '/bag-calculator/components-materials/:estimationIdentifier',
                    name: 'bag_componentsMaterial',
                    component: () => import("./views/bag/componentsMaterial.vue"),
                },
                {
                    path: '/bag-calculator/chemicals/:estimationIdentifier',
                    name: 'bag_chemicals',
                    component: () => import("./views/bag/chemicals.vue"),
                },
                {
                    path: '/bag-calculator/accessories/:estimationIdentifier',
                    name: 'bag_accessories',
                    component: () => import("./views/bag/accessories.vue"),
                },
                {
                    path: '/bag-calculator/processes-locations-and-transports/:estimationIdentifier',
                    name: 'bag_processes',
                    component: () => import("./views/bag/processesLocationsAndTransports.vue"),
                },
                {
                    path: '/bag-calculator/packaging/:estimationIdentifier',
                    name: 'bag_packaging',
                    component: () => import("./views/bag/packaging.vue"),
                },
                {
                    path: '/bag-calculator/summary/:estimationIdentifier',
                    name: 'bag_summary',
                    component: () => import("./views/bag/summary.vue"),
                },
            ]
        },
    ];
};