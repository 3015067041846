export class Breadcrumb {
    title: string;
    link: string;
    constructor(){
        this.title = "";
        this.link = "";
    }
}

export let Roles = {
    Admin: "admin",
    Employee: "employee",
    CompanyManager: "companymanager",
    InternalUser: "internaluser",
}