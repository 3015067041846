
import { Options, Vue } from 'vue-class-component';
import { LinkClient, StripeClient } from  '@/services/Services';
import { stripeKey } from '@/config';
import { Prop } from 'vue-property-decorator';
import StripeSavedCards from './stripeSavedCards.vue';
import * as OM from '@/Model';

@Options({
    components: {
        StripeSavedCards
    }
})
export default class StripeForm extends Vue {

    @Prop() userIdentifier: string;
    @Prop() userMail: string;
    @Prop() userName: string;
    @Prop() intentCb: () => Promise<string>;
    @Prop() callback: () => void;

    showNewCard: boolean = false;
    paid: boolean = false;
    paying: boolean = false;
    cards: OM.StripeCardDetailVm[] = null;
    
    textTermsAndCondition = "By proceeding I acknowledge that I have read and agree to the terms and conditions <a target='_blank' href='{{termsAndCondition}}'>terms and conditions</a>.";
    tempAcceptedTermsAndConditions: boolean = false;
    termsAndCondition: string = "";

    stripeKey = stripeKey;
    
    selectedCard: string = "";

    saveCard: boolean = false;

    stripe: any;
    cardNumber: any;
    cardExpiry: any;
    cardCvc: any;
    cardholderName: string = "";

    clientSecret: string = "";

    setShowNewCard(){
        this.showNewCard = true;
        this.selectedCard = "";
        this.$nextTick(() => {
            this.mountStripe();
        });
    }

    created(){
        Promise.all([
            StripeClient.getSavedCards(),
            LinkClient.getTermsAndConditionsLink()
        ])
        .then( xs => {
            this.cards = xs[0];
            this.termsAndCondition = xs[1];
            if(this.cards.length == 0){
                this.setShowNewCard();
            }
        })

        this.stripe = Stripe(this.stripeKey);
        this.intentCb()
        .then(x => {
            this.clientSecret = x;
        })
    }

    mountStripe(){
        this.showNewCard = true;
        this.selectedCard = '';
        var elements = this.stripe.elements();
        var style = {
            base: {
                color: "#315498",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#aab7c4",
                },
                border: "1px solid white"
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        };
        // this.card = elements.create("card", { hidePostalCode: true, style: style });
        // this.card.mount(this.$refs.cardelement);
        this.cardNumber = elements.create('cardNumber', {
            style: style
        });
        this.cardNumber.mount(this.$refs.cardnumber);

        this.cardExpiry = elements.create('cardExpiry', {
            style: style
        });
        this.cardExpiry.mount(this.$refs.cardexpiry);
        
        this.cardCvc = elements.create('cardCvc', {
            style: style
        });
        this.cardCvc.mount(this.$refs.cardcvc);

        this.cardNumber.addEventListener('change', ({error}) => {
            const displayError = <HTMLElement>this.$refs.carderrors;
            if (error) {
                displayError.textContent = error.message;
            } else {
                displayError.textContent = '';
            }
        });
    }

    get canPay(){
        return !!this.clientSecret && this.cardholderName.trim();
    }

    pay(){
        this.paying = true;
		if(this.selectedCard != "") {
            this.confirmCardPayment(this.selectedCard);
		} else {
            // this.stripe.createToken(this.card)
            this.stripe.createToken(this.cardNumber)
            .then(result => {
                if (result.error) {
                    // Inform the user if there was an error.
                    var errorElement = <HTMLElement>this.$refs.carderrors;
                    errorElement.textContent = result.error.message;
                    this.paying = false;
                } else {
                    this.confirmCardPayment({
                        card: {
                            token: result.token.id
                        },
                        billing_details: {
                            name: this.userName,
                        },
                    })
                }
            })
		}
		
    }

    confirmCardPayment(payment_method){
        this.stripe.confirmCardPayment(this.clientSecret, {
            payment_method: payment_method,
        })
        .then(async (paymentResult) => {
            if(paymentResult.error){
                var errorElement = <HTMLElement>this.$refs.carderrors;
                errorElement.textContent = paymentResult.error.message;
                this.paying = false;
            } else {
                this.paying = false;
                if(this.saveCard){
                    await this.addCardToCustomer();
                }
                this.$emit('complete', paymentResult.paymentIntent.id);
            }
        });
    }

    addCardToCustomer(){
        return new Promise<void>((resolve, reject) => {
            this.stripe.createPaymentMethod({
                type: 'card',
                card: this.cardNumber,
                billing_details: {
                    name: this.cardholderName,
                }
            })
            .then((result) => {
                if (result.error) {
                    alert(result.error);
                } else {
                    StripeClient.addCardToCustomer({
                        userIdentifier: this.userIdentifier,
                        paymentMethodId: result.paymentMethod.id
                    })
                    .then(x => {
                        resolve();
                    })
                }
            });
        })
    }

}

