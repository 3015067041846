import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/production-phase',
            name: 'production phase',
            component: () => import("./views/productionPhase.vue")
        }
    ];
};