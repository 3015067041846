
import { mixins, Options, Vue } from 'vue-class-component';
import { CompanyClient, GarmentClient, ProductionProcessClient, ProductionPhaseConfigurationClient } from '@/services/Services';
import * as OM from '@/Model';
import { Prop } from 'vue-property-decorator';
import WithModel from '@/mixins/withModel';

@Options({})
export default class companiesTable extends mixins(WithModel) {

    @Prop({
        default: "Company"
    }) label: string;

    declare localValue: string[]; //guid list of companies

    loaded: boolean = false;
    companies: OM.TextIdentifier[] = [];
    selectedCompany: string = "";

    created(){
        CompanyClient.getAsTextIdentifierList().then(x => {
            this.companies = x
            this.loaded = true;
        })
    }

    get filteredCompanies(){
        return this.companies.filter( x => {
            if(this.localValue.indexOf(x.identifier) == -1)
                return x;
        })
    }

    addValue(){
        if(!this.selectedCompany)
            return;

        if(this.localValue.indexOf(this.selectedCompany) != -1)
            return;

        this.localValue.push(this.selectedCompany);
        this.selectedCompany = "";
    }

    removeValue(identifier: string){
        var index = this.localValue.findIndex(x => x == identifier);
        this.localValue.splice(index, 1);
    }
    
    getCompanyName(identifier: string){
        return this.companies.find(x => x.identifier == identifier).text;
    }

}
