import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/',
            name: 'home',
            component: () => import("./views/home.vue")
        },
        {
            path: '/login',
            name: 'login',
            component: () => import("./views/login.vue")
        },
        {
            path: '/register',
            name: 'register',
            component: () => import("./views/register.vue")
        },
        {
            path: '/loginByDomain',
            name: 'loginByDomain',
            component: () => import("./views/loginByDomain.vue")
        },
        {
            path: '/completeDomainLogin',
            name: 'completeDomainLogin',
            component: () => import("./views/completeDomainLogin.vue")
        }
    ];
};