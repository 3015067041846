
import { BaseAuthClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import InfoModal from './infoModal.vue';

@Options({
    components: {
    }
})
export default class UpdatePasswordModal extends Vue {

    newPassword: string = "";

    created() {
        this.newPassword = "";
    }
    
    changePassword() {
        if(!this.newPassword)
            return;

        BaseAuthClient.changePassword(this.newPassword)
        .then(x => {
            this.$opModal.closeLast();
            this.$opModal.show(InfoModal, {
                text: this.$localizationService.getLocalizedValue("Success", "Success"),
                subtitle: this.$localizationService.getLocalizedValue("Password updated successfully", "Password updated successfully"),
                deny: this.$localizationService.getLocalizedValue("Close", "Close"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
    }
}
