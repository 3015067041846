import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/electricity',
            name: 'electricity',
            component: () => import("./views/electricity.vue")
        }
    ];
};